<template>
    <div 
        class="flex items-center cursor-pointer" 
        @click="clickHandler(clickHandlerParam)">
        <div class="pr-2">
            <a-avatar 
                :size="26" 
                icon="team" 
                :src="record.workgroup_logo && record.workgroup_logo.path ? record.workgroup_logo.path : null" />
        </div>
        <span class="blue_color group_name truncate">
            {{ record.name }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        },
        openHandler: {
            type: Function,
            default: () => {}
        },
        model: {
            type: String,
            required: true
        },
        column: {
            type: Object
        },
    },
    computed: {
        isWorkgroupAndProject() {
            return this.model === 'workgroups.WorkgroupModel'
        },
        isName() {
            return this.column.key === 'name'
        },
        clickHandler() {
            if(this.isWorkgroupAndProject && this.isName)
                return this.openHandler        
            return () => {}
        },
        clickHandlerParam() {
            if(this.isWorkgroupAndProject && this.isName)
                return this.record.id
            return null
        },

    }
}
</script>